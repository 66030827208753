import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import SubMenu from './SubMenu'

function AdminMenu({ items, permissions, ...props }) {
  const { i18n } = useI18n()
  const [open, setOpen] = useState(false)

  const Icon = open ? CaretUpOutlined : CaretDownOutlined

  const toggleOpen = () => setOpen(() => !open)

  return (
    <ul className="p-0 m-0" {...props}>
      <button
        type="button"
        className={`
          border-none leading-4 pl-3 py-4 mb-0.5 relative flex items-center rounded
          bg-transparent hover:bg-gray-200
          font-bold cursor-pointer w-full
        `}
        onClick={toggleOpen}
      >
        <div className="text-black">
          {i18n('menu.admin')}
        </div>
        <div className="grow" />
        <Icon className="chevron toggle-all mr-1.5 text-[#9575cd]" />
      </button>
      <ul className="p-0 m-0">
        {
          items.map(item => (
            <SubMenu item={item} key={item.name} adminOpen={open} permissions={permissions} />
          ))
        }
      </ul>
    </ul>
  )
}

AdminMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
  })),
  navOpen: PropTypes.bool,
  permissions: PropTypes.array,
}

export default AdminMenu
