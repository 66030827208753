import * as React from 'react'
import { Switch as AntdSwitch } from 'antd'
import type { SwitchProps as AntdSwitchProps } from 'antd'

interface SwitchProps {
  name?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  labelOff?: string;
  labelOn?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  switchClassName?: string;
  labelOffClassName?: string;
  labelOnClassName?: string;
}

export default function Switch({
  name,
  defaultChecked,
  checked,
  labelOff,
  labelOn,
  onChange = () => {},
  className,
  switchClassName = 'mx-2',
  labelOffClassName,
  labelOnClassName,
  ...props
}: SwitchProps & AntdSwitchProps) {
  const controlledInput = typeof checked !== 'undefined'

  const [inputChecked, setInputChecked] = React.useState(() => controlledInput
    ? Boolean(checked)
    : Boolean(defaultChecked))

  const handleChange = (val: boolean, e: React.MouseEvent<HTMLButtonElement>) => {
    if (controlledInput) {
      onChange(val, e)
    } else {
      setInputChecked(val)
    }
  }

  const checkedProp = {
    ...(typeof checked !== 'undefined' ? { checked } : {}),
  }

  return (
    // Just add a width here from parent component and you're done
    <div className={`flex justify-between items-center ${className || ''}`}>
      {labelOff && <div className={labelOffClassName || ''}>{labelOff}</div>}
      <AntdSwitch
        defaultChecked={defaultChecked}
        onChange={handleChange}
        className={switchClassName}
        {...checkedProp}
        {...props}
      />
      {labelOn && <div className={labelOnClassName || ''}>{labelOn}</div>}
      {name && (
        <input
          type="checkbox"
          value="true"
          name={name}
          checked={controlledInput ? checked : inputChecked}
          onChange={() => {}}
          hidden
          readOnly
        />
      )}
    </div>
  )
}
