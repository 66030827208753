import * as React from 'react'
import type { ButtonProps as AntdButtonProps } from 'antd'
import { Button as AntdButton } from 'antd'

interface ButtonProps {
  type?: 'link' | 'text' | 'ghost' | 'primary' | 'dashed';
  className?: string;
}

export default function Button({
  type = 'primary',
  className,
  ...props
}: ButtonProps & AntdButtonProps) {
  return (
    <AntdButton
      className={`text-sm uppercase font-bold rounded ${className || ''}`}
      type={type}
      {...props}
    />
  )
}
