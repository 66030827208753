import * as React from 'react'
import { useRouteLoaderData } from 'react-router-dom'

/**
 * Usage:
 *   const { subscribe } = useMercure()
 *   const eventSource = subscribe('/deploy', ({ data }) => console.log(data))
 *   // ...
 *   eventSource.close()
 */
export const useMercure = () => {
  const settings = useRouteLoaderData('root')
  const { mercure } = settings

  const createEventSource = React.useCallback(topic => {
    const url = new URL(mercure.hubUrl)
    url.searchParams.append('topic', `${mercure.topicUrl}${topic}`)

    return new EventSource(url, { withCredentials: true })
  }, [mercure])

  const subscribe = React.useCallback((topic, callback) => {
    if (!mercure?.hubUrl) return null // avoid infinite loop when API return 500

    const eventSource = createEventSource(topic)
    eventSource.onmessage = (({ data }) => {
      callback({ data: JSON.parse(data) })
    })

    return eventSource
  }, [createEventSource, mercure?.hubUrl])

  return {
    subscribe,
  }
}
