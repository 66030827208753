import * as React from 'react'
import type { MenuProps } from 'antd'
import { Dropdown, Space } from 'antd'
import { DownOutlined, DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons'
import { useI18n } from 'context/I18nProvider'
import { useNotification } from 'context/NotificationProvider'
import useSWR from 'hooks/swr'
import { useBlob } from 'hooks/binary'
import Button from 'components/UI/Button'
import {
  API_URL_DOC_TEMPLATES_ACCREDITATION_PASSPORT_DOWNLOAD as downloadURL,
  API_URL_USER_ACCREDITATION_PASSPORT_TEMPLATE as templateURL,
} from 'constants/api'
import { urlFilters } from 'utils/filters'

interface PassportDownloadProps {
  userId: number;
}

export default function PassportDownload({ userId }: PassportDownloadProps) {
  const { i18n } = useI18n()
  const { downloadBlob } = useBlob()
  const { notifyError, notifySuccess } = useNotification()

  const [posting, setPosting] = React.useState(false)

  const { data, isValidating } = useSWR(templateURL)
  const { formats, id } = data || { formats: [], id: null }

  const handleClick: MenuProps['onClick'] = async e => {
    const format = e.key
    handleDownload(format)
  }

  const handleDownload = async (format: string) => {
    const payload = {
      format,
      user: userId,
      template: id,
    }

    const downloadUrl = urlFilters(downloadURL, payload)
    setPosting(true)
    try {
      await downloadBlob(downloadUrl, '_blank')
      notifySuccess()
    } catch (e) {
      notifyError(e)
    } finally {
      setPosting(false)
    }
  }

  let items: MenuProps['items'] = []

  if (formats.includes('docx')) {
    items = [...items, {
      label: 'Word',
      key: 'docx',
      icon: <FileWordOutlined />,
    }]
  }
  if (formats.includes('pdf')) {
    items = [...items, {
      label: 'Pdf',
      key: 'pdf',
      icon: <FilePdfOutlined />,
    }]
  }

  const menuProps = {
    items,
    onClick: handleClick,
  }

  // This is not possible in theory but just in case..
  if (!formats.length) return null

  if (formats.length === 1) {
    return (
      <Button loading={posting || isValidating} onClick={() => handleDownload(formats[0])}>
        <Space>
          <DownloadOutlined />
          {i18n('profile.action.download_accreditation_passport')}
        </Space>
      </Button>
    )
  }

  return (
    <Dropdown menu={menuProps}>
      <Button loading={posting || isValidating}>
        <Space>
          <DownloadOutlined />
          {i18n('profile.action.download_accreditation_passport')}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}
