import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import useSWR from 'hooks/swr'
import Spinner from 'components/Spinner'
import Modal from 'components/UI/Modal'
import PassportDownload from 'components/Accreditations/PassportDownload'
import { API_URL_USERS } from 'constants/api'

function Label({ text, value }) {
  const { i18n } = useI18n()
  return (
    <div className="flex">
      <span className="font-bold">{i18n(text)}:</span>
      &nbsp;
      <span>{value || '-'}</span>
    </div>
  )
}
Label.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string,
}

function H3({ children }) {
  return <div className="text-xl font-bold py-4">{children}</div>
}

H3.propTypes = {
  children: PropTypes.node,
}

function UserProfile({ id, onClose }) {
  const { user: loggedUser } = useAuth()
  const { i18n } = useI18n()
  const { data: user, error } = useSWR(`${API_URL_USERS}/${id}`)
  const profile = useProfile()

  function formatGender(gender) {
    if (gender === '0' || gender === 0 || gender === '') return i18n('gender.women')
    if (gender === '1' || gender === 1) return i18n('gender.men')

    return i18n('gender.other')
  }

  function formatDate(val, options = 'dd/MM/yyyy') {
    return val ? format(new Date(val), options) : null
  }

  const css = 'mb-4 pl-6 grid gap-3 grid-cols-2'

  return (
    <Modal onCancel={onClose} width="900px" footer={null}>
      {
        !profile || !user || error
          ? (<Spinner />)
          : (
            <>
              <div
                className={`absolute -top-4 p-4 text-white font-bold rounded-xl
                  bg-gradient-to-r from-violet-800 to-teal-600`}
              >
                {user?.fullName}
              </div>
              <div className="profile-modal pt-4">
                <div className="w-full flex justify-between">
                  <H3>{i18n('profile.section.contact')}</H3>
                  <div className="py-4">
                    {profile.permissions.includes('ACCREDITATION_CAN_DOWNLOAD_PASSPORT')
                      ? <PassportDownload userId={id} />
                      : null}
                  </div>
                </div>
                <div className="mb-4 pl-6 grid gap-3 grid-cols-2">
                  <Label text="label.phone" value={user.phone} />
                  <Label text="label.email" value={user.email} />
                </div>
                {(profile.userDetails === 'advanced' || loggedUser.id === id) && (
                  <>
                    <H3>{i18n('profile.section.user_informations')}</H3>
                    <div className={css}>
                      <Label text="label.gender" value={formatGender(user.gender)} />
                      <Label text="label.username" value={user.username} />
                      <Label text="label.birthdate" value={formatDate(user.birthdate)} />
                      <Label text="label.user.language" value={i18n(`locale.${user.language}`)} />
                      <Label text="label.degree" value={user.degree} />
                    </div>
                  </>
                )}
                <H3>{i18n('profile.section.organization_informations')}</H3>
                <div className={css}>
                  <Label text="label.manager" value={user.manager?.fullName} />
                  <Label text="label.manager.email" value={user.manager?.email} />
                  <Label text="label.entry_date" value={formatDate(user.employmentContract?.entryCompany)} />
                  <Label text="label.exit_date" value={formatDate(user.employmentContract?.exitCompany)} />
                  <Label text="label.organization" value={user.organization?.name} />
                  <Label text="label.establishment" value={user.place?.label} />
                  <Label text="label.site" value={user.site?.label} />
                  <Label text="label.initials" value={user.initials} />
                  {(['advanced', 'medium'].includes(profile.userDetails) || loggedUser.id === id) && (
                    <Label text="label.contract_type" value={user.employmentContract?.contractType?.name} />
                  )}
                  {(profile.userDetails === 'advanced' || loggedUser.id === id) && (
                    <Label text="label.csp" value={user.employmentContract?.csp?.name} />
                  )}
                  <Label text="label.active" value={user.enabled ? i18n('yes') : i18n('no')} />
                  {(profile.userDetails === 'advanced' || loggedUser.id === id) && (
                    <Label text="label.hourly_rate" value={user.employmentContract?.hourlyRate} />
                  )}
                  <Label text="label.trainer" value={user.trainer ? i18n('yes') : i18n('no')} />
                </div>
                {(profile.userDetails === 'advanced' || loggedUser.id === id) && (
                  <>
                    {user?.additionalDatas?.length > 0 && (
                      <>
                        <H3>{i18n('profile.section.additional_datas')}</H3>
                        <div className={css}>
                          {user.additionalDatas.map((additionalData, index) => (
                            <Label key={index} text={`userAdditionalData${index + 1}`} value={additionalData} />
                          ))}
                        </div>
                      </>
                    )}
                    <H3>{i18n('profile.section.complementary_informations')}</H3>
                    <div className={css}>
                      <Label
                        text="label.user.creationDate"
                        value={formatDate(user.creationDate, 'dd/MM/yyyy HH:mm:ss')}
                      />
                      <Label text="label.user.updateDate" value={formatDate(user.updateDate, 'dd/MM/yyyy HH:mm:ss')} />
                      <Label
                        text="label.user.lastActivity"
                        value={formatDate(user.lastActivity, 'dd/MM/yyyy HH:mm:ss')}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )
      }
    </Modal>
  )
}

UserProfile.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
}

export default UserProfile
