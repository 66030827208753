/* eslint-disable max-len */
import React from 'react'
import { createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import { sentryCreateBrowserRouter } from 'services/sentry'
import { LoginDecorator } from 'containers/Login'
import ErrorPage from 'components/UI/ErrorPage'
import Page403 from 'components/Page403'
import Page404 from 'components/Page404'
import PrivateAppContainer from 'components/PrivateAppContainer'
import PrivateRoute from 'components/Auth/PrivateRoute'
import PublicAppContainer from 'components/PublicAppContainer'
import Redirect from 'components/Redirect'
import Spinner from 'components/Spinner'
import { permissions as p } from 'constants/permissions'
import { createCollective } from 'actions/collectives'
import { createOrder } from 'actions/orders'
import { createSession } from 'actions/sessions'
import { loadProfile } from 'loaders/adminProfile'
import { loadCollective } from 'loaders/collectives'
import { loadDocument } from 'loaders/documents'
import { loadReminderUser, loadReminderRows } from 'loaders/reminders'
import {
  loadCustomFields,
  loadSession,
  loadSessionAndCustomFields,
  loadSessionPeriodForm,
  loadTab,
} from 'loaders/sessions'
import { loadSettings } from 'loaders/settings'
import { loadTraining } from 'loaders/trainings'
import { login } from 'actions/login'

// The following will be lazy loaded:
const props = { fallback: <Spinner /> }
const Accreditations = loadable(() => import('containers/Accreditations'), props)
const Admin = loadable(() => import('containers/Admin'), props)
const AdminProfilePermissions = loadable(() => import('containers/Admin/Profiles/Permissions'), props)
const Apps = loadable(() => import('containers/Apps'), props)
const Catalog = loadable(() => import('containers/Catalog'), props)
const Collectives = loadable(() => import('containers/Collective'), props)
const CollectiveForm = loadable(() => import('containers/Collective/Form'), props)
const Documents = loadable(() => import('containers/Documents'), props)
const DeletedWishes = loadable(() => import('containers/DeletedWishes/Home'), props)
const Login = loadable(() => import('containers/Login/Components/Login'), props)
const LoginRoutes = loadable(() => import('containers/Login'), props)
const NeedsSummary = loadable(() => import('containers/NeedsSummary'), props)
const PeriodEdit = loadable(() => import('containers/Session/Planning/Period/Edit'))
const PeriodNew = loadable(() => import('containers/Session/Planning/Period/New'))
const Reminders = loadable(() => import('containers/Reminders'), props)
const RemindersContent = loadable(() => import('containers/Reminders/Content'), props)
const Session = loadable(() => import('containers/Session'), props)
const SessionForm = loadable(() => import('containers/Session/Form'), props)
const Sessions = loadable(() => import('containers/Session/List'), props)
const Sso = loadable(() => import('containers/Sso'), props)
const Survey = loadable(() => import('containers/PublicSurvey'), props)
const Trainings = loadable(() => import('containers/TrainingManagement/Home'), props)
const TrainingForm = loadable(() => import('containers/TrainingManagement/Form'), props)
const TrainingPlan = loadable(() => import('containers/TrainingPlan'), props)

export const router = ({ queryClient }) => sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route id="root"
      errorElement={<ErrorPage />}
      loader={loadSettings(queryClient)}
    >
      {/* Old routes being redirected */}
      <Route index element={<Redirect path="home" />} />
      <Route exact path="/login" element={<Redirect path="login" />} />
      <Route exact path="/security/login-form" element={<Redirect path="login" />} />

      {/* Public routes (no profile, no sidebar) */}
      <Route
        path="/:lang/login"
        element={<PublicAppContainer><LoginDecorator><Login /></LoginDecorator></PublicAppContainer>}
        action={login(queryClient)}
      />
      <Route path="/:lang/login/*" element={<PublicAppContainer><LoginRoutes /></PublicAppContainer>} />
      <Route path="/:lang/survey/*" element={<PublicAppContainer><Survey /></PublicAppContainer>} />

      {/* SSO (from portal or training api) */}
      <Route exact path="/sso" element={<Sso />} />

      {/* Main app (with profile and sidebar) */}
      <Route path="/:lang" element={<PrivateAppContainer />} errorElement={<PrivateAppContainer><ErrorPage /></PrivateAppContainer>}>
        <Route index element={<Navigate to="home" replace />} />
        <Route path="apps" element={<Navigate to="home" replace />} />
        <Route path="home" element={<PrivateRoute><Apps /></PrivateRoute>} />
        <Route path="accreditations/*" element={<PrivateRoute permissions={p.accreditationBoard}><Accreditations /></PrivateRoute>} />
        <Route
          path="admin/profiles/:id/permissions"
          element={<PrivateRoute><AdminProfilePermissions /></PrivateRoute>}
          loader={loadProfile(queryClient)}
        />
        <Route path="admin/*" element={<PrivateRoute><Admin /></PrivateRoute>} />
        <Route path="catalog/*" element={<PrivateRoute permissions={p.catalog}><Catalog /></PrivateRoute>} />
        <Route
          path="collectives/new"
          element={<PrivateRoute permissions={p.collectives}><CollectiveForm /></PrivateRoute>}
          action={createCollective(queryClient)}
        />
        <Route
          path="collectives/:id/edit"
          element={<PrivateRoute permissions={p.collectives}><CollectiveForm /></PrivateRoute>}
          action={createCollective(queryClient)}
          loader={loadCollective(queryClient)}
        />
        <Route path="collectives/*" element={<PrivateRoute permissions={p.collectives}><Collectives /></PrivateRoute>} />
        <Route path="deleted-wishes/*" element={<PrivateRoute permissions={p.deletedWishes}><DeletedWishes /></PrivateRoute>} />
        <Route path="needs-summary/*" element={<PrivateRoute permissions={p.needsSummary}><NeedsSummary /></PrivateRoute>} />
        <Route
          path="documents/:id"
          element={<PrivateRoute><Documents /></PrivateRoute>}
          loader={loadDocument(queryClient)}
        />

        {/* SESSIONS */}
        <Route path="sessions/*" element={<PrivateRoute permissions={p.sessions}><Outlet /></PrivateRoute>}>
          <Route index element={<Sessions />} />
          <Route
            path="new"
            element={<SessionForm />}
            action={createSession(queryClient)}
            loader={loadCustomFields(queryClient)}
          />
          <Route
            path=":id/edit"
            element={<SessionForm />}
            action={createSession(queryClient)}
            loader={loadSessionAndCustomFields(queryClient)}
            exact
          />
          <Route
            id="session"
            path=":id/*"
            loader={loadSession(queryClient)}
          >
            <Route
              path=":tab?"
              element={<Session />}
              action={createOrder(queryClient)}
              loader={loadTab(queryClient)}
            />
          </Route>
        </Route>
        <Route
          path="sessions/:id/period/*"
          element={<PrivateRoute permissions={p.sessions}><Outlet /></PrivateRoute>}
        >
          <Route path="new" element={<PeriodNew />} loader={loadSessionPeriodForm(queryClient)} />
          <Route path=":periodId" element={<PeriodEdit />} loader={loadSessionPeriodForm(queryClient)} />
        </Route>

        {/* REMINDERS */}
        <Route path="reminders" element={<PrivateRoute><Reminders /></PrivateRoute>} loader={loadReminderUser(queryClient)}>
          <Route path=":userId">
            <Route path=":scope" element={<RemindersContent />} loader={loadReminderRows(queryClient)} />
          </Route>
        </Route>
        <Route
          path="training-management"
          element={<PrivateRoute permissions={p.trainingManagement}><Outlet /></PrivateRoute>}
        >
          <Route index element={<Trainings />} />
          <Route
            path="new"
            element={<TrainingForm />}
            loader={() => null}
          />
          <Route
            path=":id/edit"
            element={<TrainingForm />}
            loader={loadTraining(queryClient)}
          />
        </Route>
        <Route path="training-plan/*" element={<PrivateRoute permissions={p.trainingPlan}><TrainingPlan /></PrivateRoute>} />
        <Route path="403" element={<Page403 />} />
        <Route path="*" element={<PrivateRoute><Page404 /></PrivateRoute>} />
      </Route>
      <Route path="/404" element={<Page404 noSideBar />} />
    </Route>,
  ),
)
