import QueryClient from 'services/query'
import { API_URL_TRAININGS } from 'constants/api'

interface LoaderParams {
  params: {
    id?: string;
  }
}

export const loadTraining = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let training = null
    try {
      training = await client.get(`${API_URL_TRAININGS}/${id}?noDefaultImage`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return training
  }
)
