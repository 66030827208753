import * as React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'context/QueryProvider'
import { API_URL_PROFILE } from 'constants/api'

const ProfileContext = React.createContext()

export default function ProfileContextProvider({ children }) {
  const { client } = useQuery()

  const [profile, setProfile] = React.useState(() => ({
    permissions: null,
  }))

  const fetchProfile = React.useCallback(async () => {
    let data
    try {
      data = await client.get(API_URL_PROFILE)
      setProfile(prev => ({ ...prev, ...data }))
    } catch (e) {
      const allowed = [401, 403]
      if (!allowed.includes(e.response.status)) throw new Error(e)
    }
  }, [client])

  React.useEffect(() => {
    fetchProfile()
    return () => setProfile(null)
  }, [fetchProfile, client])

  const value = React.useMemo(() => ({
    ...profile,
    fetchProfile,
  }), [fetchProfile, profile])

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  )
}

ProfileContextProvider.propTypes = {
  children: PropTypes.node,
}

export function useProfile() {
  const context = React.useContext(ProfileContext)
  if (context === undefined) {
    throw new Error('ProfileContext must be used within a Provider')
  }
  return context
}

ProfileContextProvider.propTypes = {
  children: PropTypes.node,
}
