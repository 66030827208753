import * as React from 'react'
import PropTypes from 'prop-types'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

export default function CollapsableMenuItem({ open, onClick, label, icon }) {
  const MenuIcon = open ? CaretUpOutlined : CaretDownOutlined
  const Icon = () => React.cloneElement(icon, {
    className: 'text-[#9575cd] mr-2 w-6 text-[1.2rem]',
  })

  return (
    <button
      type="button"
      className={`
        border-none leading-6 pl-3 py-3 my-0.5 relative flex items-center rounded bg-transparent hover:bg-gray-200
        font-bold cursor-pointer w-full
      `}
      onClick={onClick}
    >
      <Icon />
      <div className="flex w-full text-left">
        <div>
          {label}
        </div>
        <div className="grow" />
        <MenuIcon className="mr-1.5 text-[#9575cd]" />
      </div>
    </button>
  )
}

CollapsableMenuItem.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.any, // @TODO Fix when porting to TS
}
