import * as React from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import { styledIcon } from 'services/icon'
import MenuLink from './MenuLink'

function getLink(item, vintage) {
  return item.defaultVintage && vintage
    ? `${item.href}?vintages=${vintage?.id}`
    : item.href
}

function Menu({ items, ...props }) {
  const { i18n } = useI18n()
  const { defaultVintage } = useProfile()

  return (
    <ul className="p-0 m-0" {...props}>
      {
        items.map((item, i) => (
          <li key={`${i}-${item.name}`}>
            <MenuLink
              to={getLink(item, defaultVintage)}
              className="leading-4 py-3 mb-0.5 relative flex items-center rounded hover:bg-gray-200"
            >
              <span className="text-[#9575cd] w-6 mx-2 text-center">
                {styledIcon(item.icon, { fontSize: '1.2rem' })}
              </span>
              <span className="text-[#444] font-bold">{i18n(item.name)}</span>
            </MenuLink>
          </li>
        ))
      }
    </ul>
  )
}

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.object,
    href: PropTypes.string,
  })),
  collapsed: PropTypes.bool,
  navOpen: PropTypes.bool,
}

export default Menu
