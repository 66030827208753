import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Outlet, useParams, useRouteLoaderData } from 'react-router-dom'
import { ModalProvider } from 'react-modal-hook'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import ConfigProvider from 'context/ConfigProvider'
import I18nProvider from 'context/I18nProvider'
import SidebarProvider from 'context/SidebarProvider'
import NotificationProvider from 'context/NotificationProvider'
import ProfileProvider from 'context/ProfileProvider'
import Header from 'components/Header'
import PageWrapper from 'components/PageWrapper'
import RequestOverLay from 'components/RequestOverlay'
import SideBar from 'components/SideBar'
import Zendesk from 'components/Zendesk'
import ScrollToTop from './ScrollToTop'
import SWRConfig from '../SWRConfig'

function AppContainer({ header, children }) {
  const settings = useRouteLoaderData('root')
  const { locales } = settings
  const { lang } = useParams()

  if (locales?.length && !locales.includes(lang)) return <Navigate replace to="/404" />

  return (
    <I18nProvider>
      <ScrollToTop />
      <SWRConfig>
        <NotificationProvider>
          <ConfigProvider>
            <ProfileProvider>
              <ModalProvider>
                <SidebarProvider>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <RequestOverLay />
                    <Zendesk />
                    <SideBar />
                    <div id="filters-root"></div>
                    <PageWrapper>
                      <main className="w-full h-full">
                        {header && <Header />}
                        <div className="w-full h-full p-12 pt-1">
                          <Outlet />
                          {!!children ? children : null}
                        </div>
                      </main>
                    </PageWrapper>
                  </QueryParamProvider>
                </SidebarProvider>
              </ModalProvider>
            </ProfileProvider>
          </ConfigProvider>
        </NotificationProvider>
      </SWRConfig>
    </I18nProvider>
  )
}

AppContainer.defaultProps = {
  header: true,
}

AppContainer.propTypes = {
  header: PropTypes.bool,
  children: PropTypes.node,
}

export default AppContainer
