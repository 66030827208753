import { API_URL_ADMIN_PROFILES } from 'constants/api'
import QueryClient from 'services/query'

interface ParamsType {
  params: {
    id: string;
  }
}

export const loadProfile = (client: QueryClient) => (
  async ({ params }: ParamsType) => {
    const { id } = params

    let profile = null

    try {
      profile = await client.get(`${API_URL_ADMIN_PROFILES}/${id}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }

    return profile
  }
)
