import React from 'react'

import PropTypes from 'prop-types'
import { useModal } from 'react-modal-hook'

import UserIcon from 'components/UserIcon'
import Clickable from 'components/Clickable'
import UserProfile from 'components/UserProfile'

import './styles.scss'

const UserLabel = ({ id, reference, enabled, internal, label, withIcon, withEllipsis, className }) => {
  const [showProfileModal, hideProfileModal] = useModal(() => (
    <UserProfile
      id={id}
      onClose={hideProfileModal}
    />
  ), [id])

  const handleClick = () => {
    if (!id) return
    showProfileModal()
  }

  return (
    <Clickable
      data-test-reference={reference}
      className={`eleo-user-label ${id && 'eleo-user-label-hover'}`}
      onClick={handleClick}
    >
      {withIcon && (<UserIcon enabled={enabled} internal={internal} />)}
      <div
        className={`eleo-user-name ${className}`}
        style={{ textOverflow: withEllipsis && 'ellipsis' }}
      >
        {label}
      </div>
    </Clickable>
  )
}

UserLabel.defaultProps = {
  enabled: true,
  internal: true,
  withIcon: true,
  withEllipsis: true,
  className: '',
  label: '',
}

UserLabel.propTypes = {
  id: PropTypes.number,
  reference: PropTypes.string,
  enabled: PropTypes.bool,
  internal: PropTypes.bool,
  withIcon: PropTypes.bool,
  withEllipsis: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
}

export default UserLabel
