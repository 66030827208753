import QueryClient from 'services/query'
import { API_COLLECTIVES } from 'constants/api-v2'

interface LoaderParams {
  params: {
    id?: string;
  }
}

export const loadCollective = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let collective
    try {
      collective = await client.get(`${API_COLLECTIVES}/${id}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
    }
    return collective
  }
)
