import * as React from 'react'
import { useLocation, useRouteLoaderData } from 'react-router-dom'
import { useProfile } from 'context/ProfileProvider'
import { useSidebar } from 'context/SidebarProvider'
import { PAGES_WITHOUT_SIDEBAR } from 'constants/sidebar'
import AdminMenu from './AdminMenu'
import Header from './Header'
import Menu from './Menu'
import { adminItems, getItems } from './items'

// Menu items can have several permissions or no permission key at all...
function itemHasPermission(item, permissions) {
  if (!('permissions' in item)) return true
  return (item.permissions.some(p => permissions?.includes(p)))
}

function SideBar() {
  const settings = useRouteLoaderData('root')
  const { tableauURL, hseEnabled } = settings
  const { pathname } = useLocation()
  const { permissions } = useProfile()
  const { collapsed, floating, navOpen, setFloating, toggleNav, wide } = useSidebar()

  const left = React.useMemo(() => wide ? 'left-0' : '-left-[250px]', [wide])
  const overflow = React.useMemo(() => wide ? 'overflow-y-auto' : 'overflow-y-hidden', [wide])

  const items = getItems({ tableauURL, hseEnabled })

  const match = /(\/[a-z]{2})\/([\w]+)/.exec(pathname)
  const excludedPath = match && PAGES_WITHOUT_SIDEBAR.includes(match[2])
  if (excludedPath) return null

  /**
   * Hard to read: retrieve all permissions from admin sub.menu
   * Flat them, filter them and remove duplicates
   */
  let adminPermissions = adminItems.map(item => {
    const mainPermissions = item?.permissions || []
    const childrenPermissions = item.children.map(child => child?.permissions) || []
    return mainPermissions.concat(childrenPermissions)
  }).flat(3).filter(p => typeof p !== 'undefined')
  // Remove duplicates
  adminPermissions = [...new Set(adminPermissions)]

  const showAdminMenu = adminPermissions.some(p => permissions?.includes(p))

  const handleMouseEnter = () => {
    if ((collapsed || !navOpen) && !floating) setFloating(true)
  }
  const handleMouseLeave = () => {
    if (!!floating) setFloating(false)
  }

  return (
    <>
      <aside
        id="nav-root"
        className={`
          bg-gray-100 flex flex-col fixed top-0 bottom-0
          w-[256px] z-[60] ${left}
          shadow-md shadow-slate-100
          transition-all duration-500 ease
          ${floating ? 'float' : 'not-float'}
        `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Header navOpen={navOpen} onMenuClick={toggleNav} />
        <nav
          className={`p-1 pb-20 relative overflow-x-hidden ${overflow}`}
        >
          <Menu items={items.filter(item => itemHasPermission(item, permissions))} />
          {showAdminMenu && (
            <>
              <hr className="mx-3 my-1 h-[1px] border-none bg-gray-300" />
              <AdminMenu
                items={adminItems.filter(item => itemHasPermission(item, permissions))}
                permissions={permissions}
              />
            </>
          )}
        </nav>
        <div id="filters-button" />
      </aside>
      <div id="filters-root" className="z-10" />
    </>
  )
}

export default SideBar
