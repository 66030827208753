/* eslint-disable react/prop-types */
import * as React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { format as formatFns } from 'date-fns'
// @TODO This will need to be updated when/if we add a new locale :/
import { de, enGB, fr } from 'date-fns/locale'
import { getDefaultLocale } from 'utils/locale'

const locales = { de, en: enGB, fr }

const dayJsLocales = {
  de: import('dayjs/locale/de'),
  en: import('dayjs/locale/en'),
  fr: import('dayjs/locale/fr'),
  // Avoid an error in dev env
  ...(process.env.NODE_ENV === 'development'
    ? { fi: import('dayjs/locale/fi') }
    : {}),
}

// cache for 15 minutes
const TRANSLATION_CACHE_DELAY_SECONDS = 60 * 15

// UTILS (was in utils/string before)
const internali18key = (item, key, locale) => item.translations
  && item.translations[locale]
  && item.translations[locale][key]
// END OF UTILS

const I18nContext = React.createContext()

function I18nProvider({ fake, children, fixtures }) {
  const [ready, setReady] = React.useState()
  const [translations, setTranslations] = React.useState()
  const [locale, setLocale] = React.useState(() => {
    if (fake) return 'fr'
    return getDefaultLocale()
  })

  const setDayJsLocale = React.useCallback(() => {
    dayJsLocales[locale].then(() => {
      dayjs.locale(locale)
    })
  }, [locale])

  // When locale is changed (components/Header), translations for that locale are fetched
  React.useEffect(() => {
    const fetchTranslations = async () => {
      if (fixtures) setTranslations(fixtures)
      if (fake) return
      const cache = Math.floor(new Date().getTime() / (1000 * TRANSLATION_CACHE_DELAY_SECONDS))
      const url = `/translations/messages.${locale}.json?cache=${cache}`
      let trans = {}
      try {
        const resp = await axios.get(url)
        trans = resp.data
      } catch {
        // pass
      }
      document.documentElement.setAttribute('lang', locale)
      window.localStorage.setItem('locale', locale)
      setTranslations(trans)
      setDayJsLocale(locale)
      setReady(true)
    }

    fetchTranslations()
  }, [fake, fixtures, locale, setDayJsLocale])

  const i18n = React.useCallback(key => (
    translations && translations[key] ? translations[key] : key
  ), [translations])

  const i18key = React.useCallback((item, key, fallback = true) => {
    if (!item) return item
    if (!fallback) return internali18key(item, key, locale)
    return internali18key(item, key, locale)
        || internali18key(item, key, 'fr')
        || internali18key(item, key, 'en')
        || item[key]
  }, [locale])

  const i18name = React.useCallback((item, fallback) => i18key(item, 'name', fallback), [i18key])

  const formatDate = React.useCallback((date, format, options) => (
    formatFns(date, format, { locale: locales[locale], ...options })
  ), [locale])

  const value = React.useMemo(() => ({
    i18key,
    i18n,
    i18name,
    format: formatDate,
    locale,
    setLocale,
  }), [i18key, i18n, i18name, formatDate, locale])

  return (
    <I18nContext.Provider value={value}>
      { ready || fake ? children : null}
    </I18nContext.Provider>
  )
}

export function useI18n() {
  const context = React.useContext(I18nContext)
  if (context === undefined) {
    throw new Error('I18nContext must be used within a Provider')
  }
  return context
}

export default I18nProvider
