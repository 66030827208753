import * as React from 'react'
import { Dropdown as AntdDropdown } from 'antd'
import type { DropDownProps as AntdDropDownProps } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { styledIcon } from 'services/icon'

interface ItemProps {
  key: string;
  label?: string;
  onClick?: () => void;
  icon?: any;
  disabled?: boolean;
}

interface DropDownProps {
  items: ItemProps[];
  placement: string;
  className: string;
}

export default function Dropdown({
  items,
  children,
  placement = 'bottomRight',
  className = '',
  ...props
}: DropDownProps & AntdDropDownProps) {
  const content = children || (
    <div className={`hover:cursor-pointer ${className}`}>
      <MoreOutlined />
    </div>
  )

  return (
    <AntdDropdown
      trigger={['click']}
      menu={{
        items: items.map(item => ({
          ...item,
          // Bigger icons
          ...('icon' in item
            ? { icon: styledIcon(item.icon, { fontSize: '1.1rem' }) }
            : {}
          ),
        })),
      }}
      {...props}
      placement={placement}
    >
      {content}
    </AntdDropdown>
  )
}
