/* eslint-disable react/prop-types */
import * as React from 'react'
import PropTypes from 'prop-types'
import AuthService from 'services/auth'

const AuthContext = React.createContext(undefined)

export default function AuthProvider({ auth, children }) {
  const [user, setUser] = React.useState()
  // When clicking logout we do not want the `?from=...` part
  // @see https://gitlab.com/eleo-rh/training/-/issues/8669
  const [bypassFrom, setBypassFrom] = React.useState(false)

  const logout = React.useCallback(() => {
    auth.logout()
  }, [auth])

  const login = React.useCallback(token => {
    loginFromToken(token)
    setUser(auth.getUser())
  }, [auth, loginFromToken])

  /**
   * SSO and Old Portal app come to this app with their own token
   * @see containers/Sso
   */
  const loginFromToken = React.useCallback(token => {
    auth.login(token)
    setUser(auth.getUser())
    setBypassFrom(false)
  }, [auth])

  const refreshToken = React.useCallback(t => {
    auth.login(t)
    setUser(auth.getUser())
  }, [auth])

  // First render
  React.useEffect(() => {
    if (auth.validToken) {
      setUser(auth.getUser())
    } else {
      auth.deleteToken()
    }
    return () => {}
  }, [auth])

  const value = React.useMemo(() => ({
    auth,
    bypassFrom,
    login,
    logout,
    loginFromToken,
    refreshToken,
    user,
  }), [auth, bypassFrom, login, logout, loginFromToken, refreshToken, user])

  return <AuthContext.Provider value={value}>{ children }</AuthContext.Provider>
}

AuthProvider.propTypes = {
  auth: PropTypes.instanceOf(AuthService),
  children: PropTypes.node,
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('AuthContext must be used within a Provider')
  }
  return context
}
