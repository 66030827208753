import QueryClient from 'services/query'
import {
  API_REMINDERS,
  API_USERS_REMINDER_RECIPIENTS,
} from 'constants/api-v2'

interface LoaderParams {
  params: {
    userId?: string;
    scope?: string;
  }
}

export const loadReminderUser = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    let user = {}
    let error = null

    try {
      const { userId } = params

      if (userId !== 'all') {
        user = await client.get(`${API_USERS_REMINDER_RECIPIENTS}/${userId}`)
      }
    } catch (e: any) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      } else {
        error = e
      }
    }
    // Properly throw an error catched by router ErrorElement
    if (error !== null) throw new Error(error)

    return { user }
  }
)

export const loadReminderRows = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    let rows
    try {
      const { scope, userId } = params

      let url = `${API_REMINDERS}/${scope}?done=false`
      if (userId !== 'all') url += `&recipients=${userId}`

      rows = await client.get(url)
    } catch {
      // Do not block loading the app
    }

    return { rows }
  }
)
