/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useI18n } from 'context/I18nProvider'
import MenuLink from './MenuLink'
import CollapsableMenuItem from './CollapsableMenuItem'

function SubMenu({ item, adminOpen, permissions }) {
  const { i18n } = useI18n()
  const [open, setOpen] = useState(false)
  const listRef = useRef()

  // This is a bit weird but AdminOpen can overwrite open and vice-versa
  useEffect(() => {
    setOpen(adminOpen)
  }, [adminOpen])

  // just so we can use css transition on height
  const maxHeight = open ? item.children.length * 48 : 0

  const children = item.children.filter(child => !child.permissions
    || !child.permissions.length
    || !!child.permissions.filter(p => permissions.includes(p)).length)

  const toggleOpen = () => setOpen(() => !open)

  if (children.length === 0) return null

  return (
    <li>
      <CollapsableMenuItem
        open={open}
        onClick={toggleOpen}
        icon={item.icon}
        label={i18n(item.name)}
      />
      <div className="overflow-hidden w-full transition-all duration-500 ease" style={{ maxHeight }} ref={listRef}>
        <ul className="p-0 m-0">
          {
            children.map(child => (
              <li key={child.name}>
                <MenuLink
                  to={child.href}
                  className="leading-4 py-3 mb-0.5 relative flex items-center rounded hover:bg-gray-200"
                >
                  <span className="text-[#444] font-bold ml-[2.9rem]">{i18n(child.name)}</span>
                </MenuLink>
              </li>
            ))
          }
        </ul>
      </div>
    </li>
  )
}

SubMenu.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.object,
    children: PropTypes.array,
  }).isRequired,
  adminOpen: PropTypes.bool,
  permissions: PropTypes.array,
}

export default SubMenu
