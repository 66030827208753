import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import store from './store'
import './styles.scss'

const RequestOverLay = ({ children }) => {
  const [pending, setPending] = useState(0)

  useEffect(() => {
    store.register(setPending)
  }, [setPending])

  return (
    <div className="request-overlay">
      <div className={cx('request-overlay__layer', { pending: pending > 0, debug: window.overlayDebug })}>
        { pending > 0 ? pending : '' }
      </div>
      {children}
    </div>
  )
}

RequestOverLay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default RequestOverLay
