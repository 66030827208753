import * as React from 'react'
import PropTypes from 'prop-types'
import { useProfile } from 'context/ProfileProvider'
import Page403 from 'components/Page403'
import Spinner from 'components/Spinner'

function PrivateRoute({ permissions, children }) {
  const { permissions: profilePermissions } = useProfile()

  function hasPermissions() {
    if (!permissions.length) return true
    return permissions.some(p => profilePermissions?.includes(p))
  }

  // Permissions are not fetched yet
  if (!profilePermissions) return <Spinner />

  // Permissions OK
  if (hasPermissions()) return children

  // No permissions: 403
  return <Page403 />
}

PrivateRoute.defaultProps = {
  permissions: [],
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  permissions: PropTypes.array,
}

export default PrivateRoute
