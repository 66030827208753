import { BooleanParam, decodeDelimitedArray, encodeDelimitedArray, StringParam, withDefault } from 'use-query-params'
import { fixUrlParams } from 'utils/string'

const updateSearchParams = (params, searchParams) => {
  if (params && Object.keys(params).length) {
    Object.keys(params).forEach(key => {
      const value = params[key]
      /**
       * a param value can be `false`
       * ...url&internal=false
       */
      if ((value || value === false) && value !== 'undefined') {
        if (Array.isArray(value)) {
          if (value.length) {
            value.forEach(v => {
              searchParams.append(`${key}[]`, v)
            })
          } else {
            searchParams.delete(key)
          }
        } else {
          searchParams.set(key, [value])
        }
      } else searchParams.delete(key)
    })
  }
}

export const urlFilters = (urlRaw, filters) => {
  const loc = window.location
  const full = `${loc.protocol}//${loc.hostname}:${loc.port || ''}`
  const url = new URL(urlRaw, full)
  const searchParams = new URLSearchParams(url.search)

  updateSearchParams(filters, searchParams)
  let baseUrl = `${url.origin}${url.pathname}`
  const params = searchParams.toString()

  if (params) baseUrl += `?${params}`

  return new URL(baseUrl).toString()
}

export const urlParams = filters => {
  const searchParams = new URLSearchParams()
  updateSearchParams(filters, searchParams)
  return searchParams.toString()
}

/**
 * transform this:
 * {
 *   trainings: [
 *     {id: 123, name:'test'}
 *     {id: 124, name:'toto'}
 *   ],
 *   themes: [
 *     {id: 512, name:'atheme'}
 *   ]
 * }
 *
 * into:
 * {
 *   trainings: [123, 124],
 *   themes: [512]
 * }
 */
export const filtersToIds = filters => Object.keys(filters).reduce(
  (acc, key) => {
    const value = filters[key]
    const transformedValue = Array.isArray(value) ? value.map(item => item.id || item.value) : value
    return ({
      ...acc,
      [key]: transformedValue,
    })
  },
  {},
)

/**
 * Used in the Catalog for Training/Accreditation Wish creation
 */
export const mapCatalogFilters = filters => ({
  ...filtersToIds(filters),
  withInactives: filters.usersEnabled !== 'true',
  withExternals: filters.internal !== 'true',
  usersEnabled: undefined,
  internal: undefined,
})

export const paramsMapInt = params => params.map(p => ({ id: parseInt(p, 10) }))
export const paramsMapStr = params => params.map(p => ({ id: p }))

/** transform filters (filters used in training-plan, collective, wish, rdb)
 * only array values of filters are transformed
 */
export const filtersOptions = (filters, transformFn) => Object.entries(filters)
  .reduce((result, entry) => {
    const key = entry[0]
    const values = entry[1]
    return {
      ...result,
      [key]: Array.isArray(values) ? values.map(v => typeof v === 'object' ? transformFn(v) : v) : values,
    }
  }, {})
/** transform filters (filters used in training-plan, collective, wish, rdb)
 * converts Select options (value/label) to (id/name)
 */
export const toIdName = option => ({ id: option.value, name: option.label })
/** transform filters (filters used in training-plan, collective, wish, rdb)
 * converts filters entries (id/name) to select options (value/label)
 */
export const toValueLabel = option => ({ value: option.id, label: option.name })

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayParam = {
  encode: array => encodeDelimitedArray(array, ','),
  decode: arrayStr => decodeDelimitedArray(arrayStr, ','),
}

export const commonQueryParams = {
  budgets: withDefault(CommaArrayParam, []),
  cascade: BooleanParam,
  categories: withDefault(CommaArrayParam, []),
  establishments: withDefault(CommaArrayParam, []),
  fictive: StringParam,
  initials: withDefault(CommaArrayParam, []),
  internal: StringParam,
  managers: withDefault(CommaArrayParam, []),
  mandatory: StringParam,
  organizations: withDefault(CommaArrayParam, []),
  orientations: withDefault(CommaArrayParam, []),
  pathway: StringParam,
  perimeters: withDefault(CommaArrayParam, []),
  priorities: withDefault(CommaArrayParam, []),
  projects: withDefault(CommaArrayParam, []),
  reasons: withDefault(CommaArrayParam, []),
  regulatory: StringParam,
  sites: withDefault(CommaArrayParam, []),
  status: withDefault(CommaArrayParam, []),
  themes: withDefault(CommaArrayParam, []),
  trainings: withDefault(CommaArrayParam, []),
  types: withDefault(CommaArrayParam, []),
  users: withDefault(CommaArrayParam, []),
  usersEnabled: StringParam,
  vintages: withDefault(CommaArrayParam, []),
}

export const parseQueryParams = query => ({
  ...(query.budgets?.length && { budgets: paramsMapInt(query.budgets) }),
  ...(query.priorities?.length && { priorities: paramsMapInt(query.priorities) }),
  ...(!!query.cascade && { cascade: query.cascade }), // Boolean
  ...(query.categories?.length && { categories: paramsMapInt(query.categories) }),
  ...(query.establishments?.length && { establishments: paramsMapInt(query.establishments) }),
  ...(!!query.fictive && { fictive: query.fictive }), // Boolean
  ...(!!query.pathway && { pathway: query.pathway }), // Boolean
  ...(!!query.regulatory && { regulatory: query.regulatory }), // Boolean
  ...(!!query.mandatory && { mandatory: query.mandatory }), // Boolean
  ...(query.initials?.length && { initials: paramsMapStr(query.initials) }),
  ...(!!query.internal && { internal: query.internal }), // String
  ...(query.managers?.length && { managers: paramsMapInt(query.managers) }),
  ...(query.sites?.length && { sites: paramsMapInt(query.sites) }),
  ...(query.status?.length && { status: paramsMapStr(query.status) }),
  ...(query.themes?.length && { themes: paramsMapInt(query.themes) }),
  ...(query.trainings?.length && { trainings: paramsMapInt(query.trainings) }),
  ...(query.users?.length && { users: paramsMapInt(query.users) }),
  ...(!!query.usersEnabled && { usersEnabled: query.usersEnabled }), // String
  ...(query.vintages?.length && { vintages: paramsMapInt(query.vintages) }),
  ...(query.projects?.length && { projects: paramsMapInt(query.projects) }),
  ...(query.reasons?.length && { reasons: paramsMapInt(query.reasons) }),
  ...(query.types?.length && { types: paramsMapInt(query.types) }),
  ...(query.orientations?.length && { orientations: paramsMapInt(query.orientations) }),
  ...(query.perimeters?.length && { perimeters: paramsMapInt(query.perimeters) }),
  ...(query.organizations?.length && { organizations: paramsMapInt(query.organizations) }),
  ...(query.trainingOrganizations && { trainingOrganizations: paramsMapInt(query.trainingOrganizations) }),
  ...(query.trainers && { trainers: paramsMapInt(query.trainers) }),
  ...(query.locations && { locations: paramsMapInt(query.locations) }),
  ...(query.organizers && { organizers: paramsMapInt(query.organizers) }),
})

/**
 * build filters that will be used in Select components
 * with dependencies to other fields
 */
export const filtersDependencies = (filters, property) => {
  const reworked = { ...filters, population: true }
  delete reworked[property] // do not filter on self
  if (property === 'managers') {
    delete reworked.users
    delete reworked.population
  }
  if (property === 'trainings') {
    delete reworked.status
  }
  return filtersToIds(reworked)
}

/**
 * build the url used in filters Select with dependencies to other fields
 * + add population=true
 * + special cases for managers
 */
export const filtersDependenciesUrl = (url, filters, property) => {
  const filtersInUrl = filtersDependencies(filters, property)
  const baseUrl = urlFilters('', filtersInUrl)
  const params = baseUrl.includes('?') ? baseUrl.slice(baseUrl.indexOf('?')) : ''
  return fixUrlParams(`${url}${params}`)
}
