class Store {
  constructor() {
    this.nbPendingRequest = 0
  }

  register(setPending) {
    this.setPending = setPending
  }

  push() {
    this.nbPendingRequest += 1
    if (this.setPending) this.setPending(this.nbPendingRequest)
  }

  pop() {
    this.nbPendingRequest -= 1
    if (this.setPending) this.setPending(this.nbPendingRequest)
  }
}

export default new Store()
