import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

function MenuLink({ to, children, className, ...props }) {
  if (to && to?.startsWith('http')) {
    return (
      <a href={to} className={className} {...props}>
        {children}
      </a>
    )
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) => `${className} ${isActive ? 'bg-gray-200' : ''}`}
      {...props}
    >
      {({ isActive }) => (
        <>
          {isActive
            ? <div className="absolute w-[3px] h-[31px] mr-[1px] bg-[#92cc60] left-0.5 top-1" />
            : null}
          {children}
        </>
      )}
    </NavLink>
  )
}

MenuLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default MenuLink
