import QueryClient from 'services/query'
import { API_DOCUMENTS } from 'constants/api-v2'

interface LoaderParams {
  params: {
    id?: string;
  }
}

export const loadDocument = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let document = null
    let error = null

    try {
      document = await client.get(`${API_DOCUMENTS}/${id}`)
    } catch (e: any | unknown) {
      if (e.response.status === 404) {
        throw new Response('', { status: 404 })
      }
      error = (e.response.message)
    }
    return { document, error }
  }
)
