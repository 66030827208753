/* eslint-disable react/prop-types, camelcase */
import React from 'react'
import { ConfigProvider } from 'antd'
import de_DE from 'antd/lib/locale/de_DE'
import en_GB from 'antd/lib/locale/en_GB'
import fr_FR from 'antd/lib/locale/fr_FR'
import 'antd/dist/reset.css'
import { useI18n } from 'context/I18nProvider'

// @TODO Theme colors management will come here or in `hooks/theme.ts`
export const customTheme = {
  colorPrimary: '#009688', // green
  colorSecondary: '#673ab7', // violet
}

function MyConfigProvider({ children }) {
  const { locale } = useI18n()
  const locales = {
    de: de_DE,
    en: en_GB,
    fr: fr_FR,
  }

  React.useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
        :root {
          --primary: ${customTheme.colorPrimary};
          --secondary: ${customTheme.colorSecondary};
        }
    `
    document.head.appendChild(style)

    return () => document.head.removeChild(style)
  }, [])

  return (
    <ConfigProvider
      locale={locales[locale]}
      theme={{
        token: {
          colorPrimary: customTheme.colorPrimary,
          colorLink: customTheme.colorPrimary,
          colorLinkActive: customTheme.colorPrimary,
          colorLinkHover: `${customTheme.colorPrimary}85`,
        },
        components: {
          Tooltip: {
            zIndexPopup: 2000,
          },
          Notification: {
            width: 550,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default MyConfigProvider
