import * as React from 'react'
import { Link, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom'
import { notification, Progress } from 'antd'
import { CaretDownOutlined, GlobalOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons'
import useSWR from 'hooks/swr'
import { useMercure } from 'hooks/mercure'
import { useNotification } from 'context/NotificationProvider'
import { useQuery } from 'context/QueryProvider'
import { useAuth } from 'context/AuthProvider'
import { useI18n } from 'context/I18nProvider'
import { useProfile } from 'context/ProfileProvider'
import { useSidebar } from 'context/SidebarProvider'
import Button from 'components/UI/Button'
import Dropdown from 'components/UI/Dropdown'
import HseSwitcher from 'components/HseSwitcher'
import Notification from 'components/Notification'
import ReminderIcon from 'components/Reminders/Icon'
import Tooltip from 'components/UI/Tooltip'
import UserLabel from 'components/UserLabel'
import { API_URL_SSO_LOGOUT, API_URL_PROFILE_SWITCH } from 'constants/api'
import { API_REMINDERS_COUNT } from 'constants/api-v2'

function Header() {
  const settings = useRouteLoaderData('root')
  const navigate = useNavigate()
  const { permissions } = useProfile()
  const { pathname, search } = useLocation()
  const { logout, refreshToken, user } = useAuth()
  const { i18n, locale: lang, setLocale } = useI18n()
  const { client } = useQuery()
  const { notifyError } = useNotification()
  const { subscribe } = useMercure()
  const { toggleNav, wide } = useSidebar()
  const [api, contextHolder] = notification.useNotification()

  const [currentProfile, setCurrentProfile] = React.useState(user?.currentProfile)
  // Warning text which will appear when we get a new deploy notification
  const [warning, setWarning] = React.useState()

  React.useEffect(() => {
    const eventSourceDeploy = subscribe('/deploy', () => setWarning(i18n('global.new-version')))
    const eventSourceRenewableRetroaction = subscribe(`/user/${user.id}/notification`, ({ data }) => {
      const description = (
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
          {data.percent !== null && <Progress percent={data.percent} />}
        </>
      )

      api[data.notificationType]({
        key: data.key,
        message: data.message,
        description,
        duration: 0,
        placement: 'bottomRight',
      })
    })

    return () => {
      eventSourceDeploy?.close()
      eventSourceRenewableRetroaction?.close()
    }
  }, [api, i18n, subscribe, user.id])

  const withReminders = permissions?.includes('SESSION_CAN_VIEW_TASK')

  const { data: reminders } = useSWR(
    withReminders ? `${API_REMINDERS_COUNT}?done=false` : null,
    { refreshInterval: 60000 },
  )

  React.useEffect(() => {
    setCurrentProfile(user?.currentProfile)
    return () => {}
  }, [user])

  const hasMultipleProfiles = () => (user?.profiles || []).filter(profile => profile.name !== currentProfile).length > 0

  const handleProfileClick = val => {
    if (!hasMultipleProfiles()) return

    client.post(API_URL_PROFILE_SWITCH.replace(':id', val))
      .then(response => {
        refreshToken(response.token)
        window.location.reload(false)
      })
      .catch(e => notifyError(e))
  }

  const handleSwitchLocale = val => {
    window.localStorage.setItem('locale', val)
    client.updateHeaders()
    setLocale(val)
    return navigate(`${pathname}${search}`.replace(lang, val))
  }

  const handleLogout = () => {
    logout()
    if (settings.ssoEnabled) {
      window.location.replace(API_URL_SSO_LOGOUT)
    } else {
      window.location.href = `/${lang}/login`
    }
  }

  const menuClasses = 'flex items-center cursor-pointer text-base px-4'

  if (!user) return null

  return (
    <div className="static right-2 flex justify-end text-white px-0 py-5" id="top-bar">
      {contextHolder}
      <Tooltip title={i18n('sidebar.open')} placement="right">
        <Button
          type="link"
          onClick={toggleNav}
          className={`
            text-gray-400 !hover:text-gray-400
            -top-[8px] ml-4
            border-solid border-gray-300 leading-[2.2]
            ${wide ? 'invisible' : 'visible'}
          `}
          icon={<MenuOutlined />}
        />
      </Tooltip>
      <div className="grow flex justify-center">
        {!!warning ? (
          <Notification message={warning} type="warning" afterClose={() => setWarning(null)} />
        ) : null}
      </div>
      {withReminders ? (
        <div className={`${menuClasses} border-solid border-0 border-r-2 pr-8`}>
          <Link to={`reminders/${user.id}/dates`}>
            <ReminderIcon
              count={Number(reminders?.count) || null}
              className="text-white text-2xl"
              badgeClassName="w-[24px]"
            />
          </Link>
        </div>
      ) : null}
      <Dropdown
        trigger={['hover']}
        items={(settings.locales || [])
          .filter(l => l !== lang)
          .map(l => ({
            key: l,
            label: i18n(`locale.${l}`),
            onClick: () => handleSwitchLocale(l),
          }))}
      >
        <div className="flex items-center cursor-pointer mx-6">
          <GlobalOutlined className="flex items-center text-xl mr-4" />
          {i18n(`locale.${lang}`)}
          <CaretDownOutlined className="text-sm ml-2" />
        </div>
      </Dropdown>
      <Dropdown
        trigger={['hover']}
        items={(user?.profiles || [])
          .filter(profile => profile.name !== currentProfile)
          .map(profile => ({
            ...profile,
            key: profile.id,
            onClick: () => handleProfileClick(profile.id),
          }))}
      >
        <div className="flex items-center cursor-pointer">
          {currentProfile?.label} {hasMultipleProfiles() && <CaretDownOutlined className="text-sm ml-2" />}
        </div>
      </Dropdown>
      {permissions?.includes('ACCREDITATION_SWITCH') && (
        <div className={`${menuClasses} border-solid border-0 border-r-2`}>
          <HseSwitcher />
        </div>
      )}
      <div className={`${menuClasses} font-bold`}>
        <UserLabel
          id={user?.id}
          label={`${user?.firstname} ${user?.lastname?.toUpperCase()}`}
          withIcon={false}
          withEllipsis={false}
        />
        <Tooltip placement="left" title={i18n('logout')}>
          <LogoutOutlined
            size="1.3rem"
            onClick={handleLogout}
            className="ml-4 text-red-900"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default Header
