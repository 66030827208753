interface TrainingRoom {
  '@type': string;
  '@id': string;
  id: number;
  trainingRoom: {
    name: string;
    location: object;
  }
}
interface TrainingOrganization {
  '@type': string;
  '@id': string;
  name: string;
  internal: boolean;
}
interface Trainer {
  '@type': string;
  '@id': string;
  firstName: string;
  lastName: string;
  reference: string;
}

interface Organization {
  '@type': string;
  '@id': string;
  trainingOrganization: TrainingOrganization;
  trainer: Trainer;
}

interface Period {
  trainingRooms: TrainingRoom[];
  organizations: Organization[];
}

interface Session {
  id: number;
  sessionPeriods: Period[];
}

export function getRooms(session: Session) {
  return session.sessionPeriods.reduce((acc: any, current: Period) => acc.concat(current.trainingRooms), [])
}

export function getOrganizations(session: Session) {
  return session.sessionPeriods.reduce((acc: any, current: Period) => acc.concat(current.organizations), [])
}

interface Entry {
  field: number;
  value: string;
}

// @see https://gitlab.com/eleo-rh/training/-/issues/8325
export function getCustomEntriesFromFormData(data: any) {
  const customFields = data.get('custom-fields')
  const customEntries: Entry[] = []
  if (Boolean(customFields)) {
    customFields.split(',').forEach((fieldId: string) => {
      const field = `custom-field-${fieldId}`
      const fieldValue = data.get(field)
      // An unchecked checkbox will not appear in formData (null)
      // An empty field will be an empty string ('')
      if (fieldValue !== '' && fieldValue !== null) {
        customEntries.push({
          field: Number(fieldId),
          value: fieldValue,
        })
      }
    })
  }
  return customEntries
}
