import axios from 'axios'
import useSWR, { useSWRConfig } from 'swr'
import { useQuery } from 'context/QueryProvider'
import { mapResponse } from 'services/apiPlatform'

export default function useSWRCustom(url, options) {
  const { client } = useQuery()

  const fetcher = async URL => {
    const resp = await axios.get(URL, { headers: client.headers })
    return resp?.data
  }

  const { data, error, isValidating, mutate } = useSWR(url, fetcher, options)

  return {
    data: mapResponse(data),
    error,
    isValidating,
    mutate,
    total: data ? data['hydra:totalItems'] : null,
  }
}

export {
  useSWRConfig,
}
