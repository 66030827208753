import QueryClient from 'services/query'
import { API_SETTINGS } from 'constants/api-v2'

export const loadSettings = (client: QueryClient) => (
  async () => {
    let settings = {}
    try {
      // Overwrite Headers (we do not send 'authentication' here) to avoid a 401 error in the top route,
      // which somehow blocks the redirection to 'login' page
      const options = null
      const headers = {}
      settings = await client.request(API_SETTINGS, options, headers)
    } catch {
      // Do not block loading the app
    }
    return settings
  }
)
